/* App.css */

/* Global Styles */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

/* Header */
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #2874f0;
  padding: 20px;
  color: #fff;
}

.cart {
  display: flex;
  align-items: center;
}

.cart h2 {
  margin: 0;
  padding-right: 10px;
}

.cart ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.cart li {
  margin-right: 10px;
}

.cart-item {
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 8px;
}

.cart-item img {
  width: 40px;
  height: 40px;
  object-fit: contain;
  margin-right: 8px;
}

.search-bar input {
  padding: 8px 12px;
  border-radius: 4px;
  border: none;
  font-size: 14px;
}

/* Category Buttons */
.category-buttons {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.category-buttons button {
  margin-right: 10px;
  padding: 8px 12px;
  border-radius: 4px;
  border: none;
  background-color: #f0c14b;
  color: #111;
  font-weight: bold;
  cursor: pointer;
}

.category-buttons button.active {
  background-color: #111;
  color: #fff;
}

/* Product Container */
.product-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  padding: 0 20px;
}

.product {
  border: 1px solid #ddd;
  padding: 20px;
  text-align: center;
  background-color: #fff;
  border-radius: 4px;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
}

.product:hover {
  transform: translateY(-5px);
}

.product-image {
  position: relative;
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.product-image img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: auto;
  object-fit: contain;
  transition: transform 0.3s ease-in-out;
}

.product:hover .product-image img {
  transform: translate(-50%, -50%) scale(1.1);
}

.product-details {
  margin-top: 10px;
}

.product h3 {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
}

.product p {
  margin: 10px 0;
}

.description p {
  margin: 10px 0;
  font-size: 14px;
}

.toggle-description {
  background-color: transparent;
  border: none;
  color: #007185;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
}

.toggle-description:hover {
  text-decoration: underline;
  color: #004e63;
}

.category p {
  font-size: 14px;
  color: #555;
}

.rate p {
  font-size: 14px;
  color: #555;
}

.buy-now {
  padding: 8px 12px;
  border-radius: 4px;
  border: none;
  background-color: #f0c14b;
  color: #111;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.buy-now:hover {
  background-color: #ff9f00;
  color: #fff;
}

/* Additional Animations */
@keyframes slide-in-bottom {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes scale-in {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.cart-item {
  animation: slide-in-bottom 0.3s ease-in-out;
}

.product {
  animation: scale-in 0.3s ease-in-out;
}
